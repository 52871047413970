.clickablePannelItem:hover {
	cursor: pointer;
	filter: brightness(0.9);
}

.containerStudioDetails {
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) !important;
	z-index: 10;
}

.promptPartPreviewHover:hover {
	/* darker the background of the span */
	background-color: rgba(0, 0, 0, 0.1);
}

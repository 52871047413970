.channelBoxContainer:hover {
	background-color: #f0f0f0;
	cursor: pointer;
}

.alternative-content-item-card-overlay-top {
	width: 100%;
	background: linear-gradient(to top, rgba(255, 255, 255, 0.904), rgba(255, 255, 255, 0)) !important;
	height: 40px;
	box-sizing: border-box;
}

.alternative-content-item-card-overlay {
	width: 100%;
	background: linear-gradient(to top, rgb(234, 234, 234), rgba(255, 255, 255, 0.904)) !important;
	height: 50px;
	box-sizing: border-box;
	cursor: pointer;
}

.code-block {
	border: 2px solid #e3e3e3;
	padding: 8px;
	border-radius: 5px;
	margin-top: 15px;
	min-height: 200px;
}

.mandatoryField {
	color: red;
	font-size: 0.8rem;
	font-weight: 400;
	margin-left: 0.5rem;
}

li {
	min-width: 0 !important;
}

.customSwitchContainer {
	border-color: #d2d6da;
	border-radius: 0.375rem;
	border-width: 1px;
	border-style: solid;
	padding: 0.25rem 0.75rem;
	color: #252525;
	font-family: Roboto;
	font-size: 0.875rem;
	cursor: pointer;
}

.displayLeftTab {
	overflow: auto;
	/* max-height: 60vh; */
}

.displayRightTab {
	overflow-y: scroll;
	max-height: 60vh;
}

.colorPickerContainer {
	width: 3rem;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white !important;
	cursor: pointer;
}

.previewColorPickerContainer {
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white !important;
	cursor: pointer;
}

.boxInputStyle {
	border: 1px solid #d2d6da;
	padding: 0.75rem;
	color: #495057;
	font-size: 0.875rem;
	font-weight: 400;
	font-family: Roboto, Helvetica, Arial, sans-serif;
}

.endButtonboxInputStyle {
	border: 1px solid #d2d6da;
	padding: 0.75rem;
	color: #495057;
	font-size: 0.875rem;
	font-weight: 400;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	border-radius: 0 0.375rem 0.375rem 0;
	border-left: none;
	cursor: pointer;
	transition: 0.1s all ease-in-out;
}

.endButtonboxInputStyle:hover {
	filter: brightness(90%);
}

.faibrikCustomFieldsetInput fieldset {
	border: none !important;
}
